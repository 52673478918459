<template>
    <div class="dashboard">
      <div class="profile-setup-bar">
        <p>Set up your profile to start connecting with customers!</p>
        <button class="setup-btn" @click="goToProfileSetup">Setup profile</button>
      </div>
      <div class="main-content">
        <div class="welcome-section">
          <h1>Welcome David</h1>
          <p class="subtitle">Ready to deliver top-notch services today?</p>
          
          <div class="wallet-card">
            <div class="wallet-header">
              <span>Wallet Balance</span>
              
            </div>
            
            <div class="balance">₦1,000,000</div>
            
            <div class="bonus-row"> 
              <span>Bonus: ₦200</span>
              <span>Expected <span class="info-icon"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 11C8.75 11 11 8.75 11 6C11 3.25 8.75 1 6 1C3.25 1 1 3.25 1 6C1 8.75 3.25 11 6 11Z" stroke="#555C74" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6 4V6.5" stroke="#555C74" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.99609 8H6.00058" stroke="#555C74" stroke-lineca p="round" stroke-linejoin="round"/>
                </svg>:
                </span> ₦200</span>
            </div>
            
            <button class="withdraw-btn">Withdraw</button>
          </div>
  
          <div class="transactions-section">
            <div class="section-header">
              <h2>Transactions</h2>
              <a href="#" class="see-more">See More</a>
            </div>
            
            <div class="transactions-list">
              <div v-for="(transaction, index) in transactions" :key="index" class="transaction-item">
                <div class="transaction-icon"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.33398 11.6654L12.6673 2.33203" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3.33398 4.82031V11.667H10.1807" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2.33398 14.668H13.6673" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  
                  </div>
                <div class="transaction-details">
                  <div class="transaction-title">{{ transaction.title }}</div>
                  <div class="transaction-date">{{ transaction.date }}</div>
                </div>
                <div class="transaction-amount">₦{{ transaction.amount }}</div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="commission-section">

          <div class="commission-main">

            <div class="section-header">
              <h2>Commission history</h2>
              <router-link to="#" class="see-all">See all</router-link>
            </div>
    
            <div class="commission-months">
              <div v-for="(month, index) in commissionHistory" :key="index" class="month-group">
                <h3>{{ month.date }}</h3>
                <div v-for="(item, itemIndex) in month.items" :key="itemIndex" class="commission-item">
                  <div class="commission-details">
                    <div>{{ item.title }}</div>
                    <div class="amount">₦{{ item.amount }}</div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
  
      <!-- OPPORTUNITY DESK SLIDER -->
      <div class="card-container">
        <transition name="slide" mode="out-in">
          <div class="card" :key="currentIndex">
            <div class="nav-container">
              <button class="nav prev" @click="prevSlide"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0032 13.2788L5.65648 8.93208C5.14315 8.41875 5.14315 7.57875 5.65648 7.06542L10.0032 2.71875" stroke="#2B3352" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </button>
              <button class="nav next" @click="nextSlide"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.9375 13.2788L10.2842 8.93208C10.7975 8.41875 10.7975 7.57875 10.2842 7.06542L5.9375 2.71875" stroke="#2B3352" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </button>
            </div>
            <p class="label">Opportunity desk</p>
            <h3 class="opportunity-title">{{ items[currentIndex].title }}</h3>
            <p class="description">{{ items[currentIndex].description }}</p>
            <div class="bottom-section">
              <a href="#" class="explore">Explore more <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.66602 7.33385L14.1327 1.86719" stroke="#2B3352" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.6687 4.53203V1.33203H11.4688" stroke="#2B3352" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.33398 1.33203H6.00065C2.66732 1.33203 1.33398 2.66536 1.33398 5.9987V9.9987C1.33398 13.332 2.66732 14.6654 6.00065 14.6654H10.0007C13.334 14.6654 14.6673 13.332 14.6673 9.9987V8.66536" stroke="#2B3352" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </a>
              <div class="pagination">
                <span
                  v-for="(item, index) in items"
                  :key="index"
                  :class="{ active: index === currentIndex }"
                  @click="goToSlide(index)"
                ></span>
              </div>
            </div>
          </div>
        </transition>
      </div>
          
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'VendorsHome',
    data() {
      return {
        currentIndex: 0,
        transactions: [
          {
            title: 'Direct Commission on Listed property Pin-2404107358R',
            date: 'Feb 10,2024',
            amount: '100,000'
          },
          {
            title: 'Direct Commission on Listed property Pin-2404107358R',
            date: 'Feb 10,2024',
            amount: '100,000'
          },
          {
            title: 'Direct Commission on Listed property Pin-2404107358R',
            date: 'Feb 10,2024',
            amount: '100,000'
          },
          {
            title: 'Direct Commission on Listed property Pin-2404107358R',
            date: 'Feb 10,2024',
            amount: '100,000'
          }
        ],
        commissionHistory: [
          {
            date: 'Mar 2024',
            items: [
              {
                title: 'Team commission from subscription & referal',
                amount: '100,000'
              },
              {
                title: 'Direct commission from referal',
                amount: '100,000'
              }
            ]
          },
          {
            date: 'Feb 2024',
            items: [
              {
                title: 'Direct commission from referal',
                amount: '100,000'
              },
              {
                title: 'Team commission from subscription & referal',
                amount: '100,000'
              }
            ]
          }
        ],
        items: [
        { title: "Invest in Downtown Apartments", description: "Lorem ipsum dolor sit amet consectetur. Pretium tortor viverra fermentum egestas. Euismod varius amet convallis ornare facilisis. Erat a hendrerinare..." },
        { title: "Luxury Villas for Sale", description: "Discover the finest luxury villas with premium amenities in prime locations. Perfect for investment and comfortable living." },
        { title: "Commercial Spaces Available", description: "High-end office spaces available in the heart of the city. Ideal for businesses looking for an upscale presence." }
      ]
      }
    },
    methods: {
      nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
    },
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.items.length) % this.items.length;
    },
    goToSlide(index) {
      this.currentIndex = index;
    },
    autoPlay() {
      setInterval(() => {
        this.nextSlide();
      }, 3000);
      },

      goToProfileSetup() {
      this.$router.push('/user/vendors/profile-setup');
    }
    },
    mounted() {
      this.autoPlay; 
    }
  }
  </script>
  
  <style scoped>
  /* EVERYTHING IN THIS COMPONENT SHOULD USE THE LATO FONT */
* {
  font-family: "Lato", sans-serif;
}
  .dashboard {
   
    padding: 50px 20px;
  }
  .flex {
    display: flex;
    align-items:center;
  }
  
  .flex-col {
    display: flex;
    flex-direction: column;
  }
  
  .justify-between {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  
  .profile-setup-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: hsla(227, 100%, 9%, 1);
    color: hsla(0, 0%, 100%, 1);
    padding: 1rem 20px;
    border-radius: 10px;
    height:auto;
    font-size: 16px;
    font-weight:600;
    margin-bottom:2rem;
  }
  
  .setup-btn {
    background: hsla(0, 0%, 100%, 1);
    color: hsla(221, 11%, 29%, 1);
    border: none;
    padding: 10px 16px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
  }
  


  .main-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 48px;
  }
  .welcome-section{
    grid-column: span 2;
  }
  .welcome-text h1 {
    font-size: 24px;

    font-weight:600;
    color: hsla(228, 31%, 25%, 1);
  }
  
  .subtitle {
    color: hsla(226, 15%, 39%, 1);
    font-weight: 400;
    font-size:16px;
  }
  
  .wallet-card, .transactions-section {
    background: #fff;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    margin-bottom: 24px;
  }
  
  .wallet-header {
    display: flex;
    justify-content: space-between;
    color: hsla(226, 15%, 39%, 1);
    margin-bottom: 12px;
  }
  
  .balance {
    font-size: 36px;
    font-weight: 700;
    margin: 16px 0;
    color: hsla(226, 15%, 39%, 1);
  }
  
  .bonus-row {
    display: flex;
    justify-content: space-between;
    color: hsla(226, 15%, 39%, 1);
    font-size:16px;
    font-weight: 600;
    margin-bottom: 24px;
  }
  
  .withdraw-btn {
    background: #0033EA;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 32px;
    cursor: pointer;
    font-weight: 400;
    font-size:14px;
  }
  
  .withdraw-btn:hover {
    background: #0036D9;
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .section-header h2 {
    font-size: 14px;
    color: hsla(228, 31%, 25%, 1);
    font-weight:600;
  }
  
  .see-more, .see-all {
    color: hsla(228, 31%, 25%, 1);
    font-size:12px;
    font-weight:500;
  }
  
  .transaction-item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 16px;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid #eee;
  }
  
  .transaction-icon {
    background: #2BC300;
    color: #fff;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

  
  .transaction-date, .transaction-amount{
  color: #555C74;
    margin-top: 5px;
  }
  
 

  .transaction-details{
    font-weight: 400;
    font-size: 14px;
    color: #2B3352;
  }
  .commission-section {
    width:100%;
    border-radius: 12px;

  }
  
  .month-group {
    margin-bottom: 24px;
    font-size:12px;
    font-weight:400;
    color: hsla(228, 31%, 25%, 1);
  }
  
  .month-group h3 {
    font-size: 14px;   
    font-weight:400;
  }

  .commission-main{
    background: hsla(0, 0%, 100%, 1);
    box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.05);
    padding:24px 16px;
    border-radius: 8px;
  }
  
  .commission-item {
    margin-bottom: 16px;
  }
  
  .commission-details {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight:400;
    border-bottom: 1px solid hsla(214, 32%, 91%, 1);
    padding:1rem 0;
  }
  
 
  
  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: hsla(41, 100%, 95%, 1);
    height: 300px;
    overflow: hidden;
    width:100%;
    margin-top: 3rem;
    position: relative;
    border: 1px solid hsla(53, 13%, 86%, 1);
    border-radius:8px;
  }
  .card {
    padding: 16px;
    background: inherit;
    border-radius: 10px;
    border:none;
   position: absolute;
    text-align: left;
  }
  .nav-container {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 5px;
  }
  .label {
    font-size: 12px;
    color: hsla(226, 15%, 39%, 1);
    font-weight:600;
  }
  opportunity-title {
    font-size: 16px;
    font-weight:600;
    color: hsla(228, 31%, 25%, 1);
    margin: 5px 0;
  }
  .description {
    font-size: 14px;
    color: hsla(228, 31%, 25%, 1);
    font-weight:400;
    margin-bottom: 10px;
  }
  .bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  .explore {
    font-size: 14px;
    color: hsla(228, 31%, 25%, 1);
    font-weight: 700;
    text-decoration: none;
  }
  .pagination {
    display: flex;
  }
  .pagination span {
    width: 12px;
    height: 12px;
    background: hsla(240, 5%, 83%, 1);
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
  }
  .pagination .active {
    background: hsla(228, 31%, 25%, 1);
  }
  .nav {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Slide-in Animation */
  .slide-enter-active, .slide-leave-active {
    transition: transform 0.5s ease;
  }
  .slide-enter {
    transform: translateX(100%);
  }
  .slide-leave-to {
    transform: translateX(-100%);
  }


  /* MOBILE */

  @media(max-width:1024px){
    .welcome-section,   .commission-section{
      grid-column: 1fr;
      width:100% !important;
    }

   
  }
  @media (max-width: 768px) {
    

    .main-content {
      display:grid;
       grid-template-columns: 1fr;
       gap: 32px;
       width:100%;
     }

     .commission-main{
      min-width:110% !important;
     }
    .setup-btn {
    width: max-content;
      font-size: 14px;
    
    }
    
    .dashboard {
      padding: 16px;
    }
    .card-container {
    
      min-width:110%;
      
    }
    .opportunity-desk {
      padding: 16px;
    }
    
    .opportunity-card h4 {
      font-size: 15px;
    }
    
    .opportunity-card p {
      font-size: 13px;
    }
  }
  </style>